@keyframes status {
  from {
    background-position: right 0 center;
  }
  to {
    background-position: right 100% center;
  }
}

@keyframes statusLine {
  0% {
    background-position: right 0 center;
  }
  4% {
    background-position: right 1% center;
  }

  28% {
    background-position: right 32% center;
  }
  35% {
    background-position: right 33% center;
  }

  62% {
    background-position: right 65% center;
  }
  68% {
    background-position: right 66% center;
  }

  96% {
    background-position: right 100% center;
  }
  100% {
    background-position: right 100% center;
  }
}

.shippingStatusContainer {
  --delay: 500ms;
  --stagger: 600ms;
  --empty-line-color: var(--tra-color-surface-100);
  --empty-color: var(--tra-color-surface-400);
  --fill-color: var(--tra-color-surface-700);
}

.shippingStatus {
  background-size: 200%;
  background-image: linear-gradient(to left, var(--empty-color) 50%, var(--fill-color) 50%);
  animation: 500ms ease-out status both paused;
}
.shippingStatusContainer:after {
  background-size: 200%;
  background-image: linear-gradient(to left, var(--empty-line-color) 50%, var(--fill-color) 50%);
  animation: 1700ms linear status both calc(var(--delay) + 200ms) paused;
}

.active,
.active:after {
  animation-play-state: running;
}

li:nth-child(2) > .shippingStatus {
  animation-delay: calc(var(--delay) + var(--stagger));
}
li:nth-child(4) > .shippingStatus {
  animation-delay: calc(var(--delay) + (var(--stagger) * 3));
}

li:nth-child(3) > .shippingStatus {
  animation-delay: calc(var(--delay) + (var(--stagger) * 2));
}
li:first-child > .shippingStatus {
  animation-delay: var(--delay);
}
